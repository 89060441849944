<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">Setting</v-card-text>
    </v-col>
    <v-col cols="12">
    </v-col>
  </v-row>
</template>
<script>
export default {};
</script>
<style lang="">
</style>